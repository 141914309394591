.dropdown-checkbox {
  position: relative;
  width: 200px;
}

.dropdown-checkbox button {
  background-color: #fff; /* Set your desired background color */
  border: 1px solid #ccc; /* Set your desired border color */
  color: #333; /* Set your desired text color */
  padding: 8px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.dropdown-checkbox .checkbox-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc; /* Set your desired border color */
  background-color: #fff; /* Set your desired background color */
  max-height: 200px; /* Set your desired max height */
  overflow-y: auto;
  z-index: 1;
}

.dropdown-checkbox label {
  display: block;
  padding: 8px;
  border-bottom: 1px solid #eee; /* Set your desired border color between options */
}

.dropdown-checkbox label:last-child {
  border-bottom: none; /* Remove border for the last option */
}

.dropdown-checkbox input[type="checkbox"] {
  margin-right: 8px;
}
