.cloud-asset-info {
    display: flex;
    flex-direction: column;
}

.cloud-asset-form {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.cloud-asset-input-edited {
    border: 2px solid orange;
}

.error-border {
    border: 2px solid red;
}

.cloud-asset-data {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 10px;
}
textarea {
    resize: none;
}


.cloud-asset-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3%;
    flex-direction: column;
    row-gap: 1em;
}

.cloud-asset-actions {
    display:flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.cloud-asset-image > img {
            max-width: 90%;
            max-height: 60vh;
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 5px;
}

.cloud-asset-table {
    table-layout: fixed;
    width: 90%;
    margin: 15px;
    border-collapse: collapse;
    tbody>*>* {
        border: 1px solid black;
        padding: 8px;
    }
}

.cloud-asset-image>img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
.cloud-asset-data {
    display:flex;
    flex-direction: column;
}

.cloud-asset-search {
    display: flex;
    flex-direction: column;
    column-gap: 20px;
    align-items: center;
    justify-content: center;
}

.cloud-asset-search-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: center;
    column-gap: 2vh;
}

.cloud-asset-action-button {
    background-color: lightblue;
    cursor: pointer;
    height: 4em;
    text-decoration: none;
    border-radius: 5px;
    border: 1px solid #CCCCCC;
    box-shadow: 0 0 5px rgba(66,66,66,.75);
    padding: 4px;
}

.cloud-asset-search-bar > input {
    width: 90%;
    height: 4vh;
    padding: 5px;
    font-size: 16px;
    border-width: 1px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
    color: #000000;
    border-style: solid;
    border-radius: 0;
    box-shadow: 0 0 5px rgba(66,66,66,.75);
}

.cloud-asset-search-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    padding: 20px;
}

.cloud-asset-search-actions-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.cloud-asset-search-actions-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}

.cloud-asset-search-sorting {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 5px;
    padding-left: 10px;
}

.cloud-asset-sorting-button {
    background-color: transparent;
    cursor: pointer;
    height: 4em;
    text-decoration: none;
    border-radius: 5px;
    border: 1px solid #CCCCCC;
    box-shadow: 0 0 5px rgba(66,66,66,.75);
    padding: 4px;
}

.cloud-asset-sorting-button-active {
    background-color: lightblue;
    cursor: pointer;
    height: 4em;
    text-decoration: none;
    border-radius: 7px;
    box-shadow: 0 0 5px rgba(66,66,66,.75);
    padding: 4px;
}


.cloud-asset-form-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    padding: 20px;
    margin-top: 20px;
}
