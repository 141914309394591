/*!
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

//@import "fonts";
@import "loader";


* > ::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

div {
  //display: inline-block;
  font-family: 'font-light', sans-serif
}

body {
  margin: 0px;
}

img {
  display: inline-block;
}

input {
  border: none;
  font-size: 16px;
  //border-radius: 40px !important;
}


/**
 * Flex shit
 */
.ll_v_l {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.ll_v_c {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.ll_v_r {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.ll_h_t {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
}

.ll_h_c {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.ll_h_r {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
}

/**
 * Dimension shit
 */
.match_height {
  height: 100%
}

.match_width {
  width: 100%
}

.match_all {
  height: 100%;
  width: 100%;
}

.center_h {
  margin-left: auto;
  margin-right: auto;
}

.center_v {
  margin-top: auto;
  margin-bottom: auto;
}

.center {
  margin: 0 auto;
}

.clickable {
  cursor: pointer
}


.full_screen_overlay {
  position: absolute;
  z-index: 2;
}

.full_screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.absolute {
  position: absolute;
}

.right {
  right: 0;
}

.bottom {
  bottom: 0;
}

.left {
  left: 0;
}

.top {
  top: 0;
}